import type { Action } from "../types";
import { makeAPICall } from "./apiClient";

interface RecordActionRequest {
  challengeRunId: string;
  action: Action;
}

interface LoadActionsRequest {
  challengeRunId: string;
}

interface LoadActionsResponse {
  actions: Action[];
}

/**
 * Record a user action for a challenge run
 * @param data The action data to record
 * @returns A promise that resolves when the action is recorded
 */
export async function recordAction(data: RecordActionRequest): Promise<void> {
  return makeAPICall<RecordActionRequest, void>("recordAction", data);
}

/**
 * Load actions for a challenge run
 * @param data The challenge run ID to load actions for
 * @returns A promise that resolves to the challenge run's actions
 */
export async function loadActions(
  data: LoadActionsRequest,
): Promise<LoadActionsResponse> {
  return makeAPICall<LoadActionsRequest, LoadActionsResponse>(
    "loadActions",
    data,
  );
}

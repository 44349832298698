import type { TestProgress } from "../types";
import { makeAPICall } from "./apiClient";

export interface UserProgressResponse {
  userProgress: Record<string, TestProgress>;
}

/**
 * Get the user's progress across all exams.
 * @returns A promise that resolves to the user's progress data
 */
export async function getUserProgress(): Promise<UserProgressResponse> {
  return makeAPICall<void, UserProgressResponse>(
    "fetchUserProgress",
    undefined,
  );
}

import type React from "react";
import {
  type AIMEProblemDetails,
  type AMCProblemDetails,
  type OlympiadProblemDetails,
  type ProblemDetails,
  type ProblemReference,
  isAIMEProblem,
  isAMCProblem,
  isOlympiadProblem,
} from "../types";
import AIMEProblemView from "./AIMEProblemView";
import MultipleChoiceProblemView from "./MultipleChoiceProblemView";
import OlympiadProblemView from "./OlympiadProblemView";
import ShortTextAnswerProblemView from "./ShortTextAnswerProblemView";

interface ExamProblemRendererProps {
  problemRef: ProblemReference;
  examProblem?: { type: string };
  fullProblem: ProblemDetails & ProblemReference;
  onAnswer: (answer: string) => void;
  onSkip: () => void;
  selectedAnswer: string | null;
  responses: { answer: string; timestamp: number }[];
  autoSubmit?: boolean;
}

const ExamProblemRenderer: React.FC<ExamProblemRendererProps> = ({
  examProblem,
  fullProblem,
  onAnswer,
  onSkip,
  selectedAnswer,
  responses,
  autoSubmit = false,
}) => {
  // Return null if exam problem type doesn't match full problem type
  if (examProblem && examProblem.type !== fullProblem.type) {
    return null;
  }

  const commonProps = {
    onSubmit: onAnswer,
    onSkip,
    responses,
  };

  if (isAMCProblem(fullProblem)) {
    return (
      <MultipleChoiceProblemView
        problem={fullProblem}
        onOptionSelect={onAnswer}
        autoSubmit={autoSubmit}
        selectedAnswer={selectedAnswer}
        {...commonProps}
      />
    );
  }

  if (isAIMEProblem(fullProblem)) {
    return (
      <AIMEProblemView
        problem={fullProblem}
        onUpdateAnswer={onAnswer}
        currentAnswer={selectedAnswer || ""}
        {...commonProps}
      />
    );
  }

  if (isOlympiadProblem(fullProblem)) {
    return (
      <OlympiadProblemView
        problem={fullProblem}
        onUpdateAnswer={onAnswer}
        currentAnswer={selectedAnswer || ""}
        {...commonProps}
      />
    );
  }

  // Return null if problem types don't match
  return null;
};

export default ExamProblemRenderer;

import { makeAPICall } from "./apiClient";

export interface SurveyData {
  amcScore: string;
  satisfaction: number;
  currentMathClasses: string[];
  desiredMathClasses: string[];
  schoolName: string;
  notes: string;
}

export interface SurveyResponse {
  success: boolean;
}

export async function submitSurvey(
  surveyData: SurveyData,
): Promise<SurveyResponse> {
  return makeAPICall<{ surveyData: SurveyData }, SurveyResponse>(
    "submitSurvey",
    {
      surveyData,
    },
  );
}

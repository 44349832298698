import { CheckCircle, Clock, XCircle } from "lucide-react";
import type React from "react";

export interface ProblemAttempt {
  label: string;
  answer: string | null;
  isCorrect?: boolean;
  submittedAt: string;
  timeSpent?: string;
}

export interface HoverCardProps {
  title: string;
  attempts: ProblemAttempt[];
  className?: string;
  position?: "top" | "bottom" | "left" | "right";
}

const HoverCard: React.FC<HoverCardProps> = ({
  title,
  attempts,
  className = "",
  position = "top",
}) => {
  // Compute position classes based on the position prop
  const positionClasses = {
    top: "bottom-full left-1/2 -translate-x-1/2 mb-2",
    bottom: "top-full left-1/2 -translate-x-1/2 mt-2",
    left: "right-full top-1/2 -translate-y-1/2 mr-2",
    right: "left-full top-1/2 -translate-y-1/2 ml-2",
  }[position];

  const arrowClasses = {
    top: "top-full left-1/2 -translate-x-1/2 -mt-1 border-t-white",
    bottom: "bottom-full left-1/2 -translate-x-1/2 -mb-1 border-b-white",
    left: "left-full top-1/2 -translate-y-1/2 -ml-1 border-l-white",
    right: "right-full top-1/2 -translate-y-1/2 -mr-1 border-r-white",
  }[position];

  return (
    <div
      className={`absolute ${positionClasses} z-50 min-w-[300px] ${className}`}
    >
      <div className="bg-white rounded-lg shadow-lg border border-gray-200 overflow-hidden">
        <div className="p-3 border-b border-gray-100">
          <h4 className="font-medium text-gray-900">{title}</h4>
        </div>
        <div className="p-3">
          {attempts.length === 0 ? (
            <p className="text-sm text-gray-500">No previous attempts</p>
          ) : (
            <ul className="space-y-2">
              {attempts.map((attempt, index) => (
                <li
                  key={`${attempt.label}-${index}`}
                  className="flex items-center justify-between text-sm"
                >
                  <div className="flex items-center space-x-2">
                    {attempt.isCorrect ? (
                      <CheckCircle className="w-4 h-4 text-green-500" />
                    ) : (
                      <XCircle className="w-4 h-4 text-red-500" />
                    )}
                    <span className="text-gray-700">
                      Problem {attempt.label}
                    </span>
                  </div>
                  <div className="flex items-center space-x-3">
                    {attempt.timeSpent && (
                      <div className="flex items-center text-gray-500 space-x-1">
                        <Clock className="w-4 h-4" />
                        <span>{attempt.timeSpent}</span>
                      </div>
                    )}
                    <span
                      className={`font-mono ${
                        attempt.isCorrect ? "text-green-600" : "text-red-600"
                      }`}
                    >
                      {attempt.answer || "—"}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          )}
          <div className="mt-2 text-xs text-gray-400">
            Last attempt:{" "}
            {attempts.length > 0
              ? new Date(
                  attempts[attempts.length - 1].submittedAt,
                ).toLocaleString()
              : "Never"}
          </div>
        </div>
      </div>
      <div
        className={`absolute w-2 h-2 transform rotate-45 border border-gray-200 bg-white ${arrowClasses}`}
      />
    </div>
  );
};

export default HoverCard;

import { Mic, MicOff, Save, Settings } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { initializeClaudeService, sendMessage } from "../api/claudeApi";
import type {
  AnthropicRequest,
  SpeechRecognition,
  SpeechRecognitionConstructor,
  SpeechRecognitionErrorEvent,
  SpeechRecognitionEvent,
} from "../types";

const SILENCE_THRESHOLD = 2000;
const CLAUDE_MODEL = "claude-3-5-haiku";

declare global {
  interface Window {
    SpeechRecognition?: SpeechRecognitionConstructor;
    webkitSpeechRecognition?: SpeechRecognitionConstructor;
  }
}

export default function VRVoiceInput() {
  const [isListening, setIsListening] = useState(false);
  const [text, setText] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isSupported, setIsSupported] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [showApiKeyInput, setShowApiKeyInput] = useState(true);

  const recognitionRef = useRef<SpeechRecognition | null>(null);
  const lastSpeechRef = useRef<number>(Date.now());
  const silenceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Check for API key in session storage
    const sessionApiKey = sessionStorage.getItem("anthropicApiKey");
    if (sessionApiKey) {
      setApiKey(sessionApiKey);
      initializeClaudeService(sessionApiKey);
      setShowApiKeyInput(false);
    }

    const SpeechRecognitionClass = (window.SpeechRecognition ||
      window.webkitSpeechRecognition) as
      | SpeechRecognitionConstructor
      | undefined;

    if (SpeechRecognitionClass) {
      recognitionRef.current = new SpeechRecognitionClass();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = true;
      recognitionRef.current.lang = "en-US";

      recognitionRef.current.onstart = () => {
        setIsListening(true);
        setError(null);
        lastSpeechRef.current = Date.now();
      };

      recognitionRef.current.onerror = (event: SpeechRecognitionErrorEvent) => {
        setError(`Speech recognition error: ${event.error}`);
        setIsListening(false);
      };

      recognitionRef.current.onend = () => {
        setIsListening(false);
        if (silenceTimeoutRef.current) {
          clearTimeout(silenceTimeoutRef.current);
        }
      };

      recognitionRef.current.onresult = async (
        event: SpeechRecognitionEvent,
      ) => {
        const transcript = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");

        setText(transcript);
        lastSpeechRef.current = Date.now();

        if (silenceTimeoutRef.current) {
          clearTimeout(silenceTimeoutRef.current);
        }

        silenceTimeoutRef.current = setTimeout(async () => {
          const timeSinceLastSpeech = Date.now() - lastSpeechRef.current;
          if (timeSinceLastSpeech >= SILENCE_THRESHOLD && transcript.trim()) {
            setIsProcessing(true);
            try {
              const request: AnthropicRequest = {
                messages: [
                  {
                    role: "user",
                    content: `Do not use functions. Do analyze this spoken thought and respond with ONLY either "complete" or "incomplete" without explanation or surrounding quotes. Here's the text: "${transcript}"`,
                  },
                ],
                model: CLAUDE_MODEL,
                max_tokens: 1,
              };

              const thoughtAnalysis = await sendMessage(
                request.messages[0].content,
              );

              if (thoughtAnalysis.message.includes("complete")) {
                recognitionRef.current?.stop();
                setIsListening(false);
              }
            } catch (error) {
              console.error("Error checking thought completion:", error);
              setError(
                error instanceof Error
                  ? error.message
                  : "Unknown error occurred",
              );
            } finally {
              setIsProcessing(false);
            }
          }
        }, SILENCE_THRESHOLD);
      };

      setIsSupported(true);
    } else {
      setError("Speech recognition is not supported in this browser");
      setIsSupported(false);
    }

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
      if (silenceTimeoutRef.current) {
        clearTimeout(silenceTimeoutRef.current);
      }
    };
  }, []);

  const handleApiKeySubmit = () => {
    if (apiKey.trim() === "") return;
    sessionStorage.setItem("anthropicApiKey", apiKey);
    initializeClaudeService(apiKey);
    setShowApiKeyInput(false);
  };

  const handleResetApiKey = () => {
    setApiKey("");
    setShowApiKeyInput(true);
    sessionStorage.removeItem("anthropicApiKey");
  };

  const toggleListening = () => {
    if (!recognitionRef.current || showApiKeyInput) return;

    if (isListening) {
      recognitionRef.current.stop();
    } else {
      setText("");
      recognitionRef.current.start();
    }
  };

  const saveText = () => {
    console.log("Saving text:", text);
    alert("Text saved! (check console)");
  };

  return (
    <div className="h-screen flex flex-col p-6 bg-gray-100">
      <div className="max-w-4xl mx-auto w-full flex-grow flex flex-col">
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-bold">Smart VR Voice Input</h1>
            {!showApiKeyInput && (
              <button
                onClick={handleResetApiKey}
                className="flex items-center gap-2 px-4 py-2 rounded bg-gray-200 hover:bg-gray-300 transition-colors"
                type="button"
              >
                <Settings size={20} />
                Reset API Key
              </button>
            )}
          </div>

          {showApiKeyInput ? (
            <div className="flex items-center mb-4">
              <input
                type="password"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                placeholder="Enter Anthropic API Key"
                className="flex-grow p-2 border rounded-l focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={handleApiKeySubmit}
                className="bg-blue-500 text-white px-4 py-2 rounded-r hover:bg-blue-600"
                type="button"
              >
                Submit
              </button>
            </div>
          ) : (
            <>
              <p className="text-gray-600 mb-4">
                Speak naturally - the system will detect when you've completed a
                thought.
              </p>
              {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
                  {error}
                </div>
              )}

              <div className="flex gap-4 mb-4">
                <button
                  onClick={toggleListening}
                  disabled={!isSupported || isProcessing}
                  className={`flex items-center gap-2 px-4 py-2 rounded ${
                    isListening
                      ? "bg-red-500 hover:bg-red-600"
                      : "bg-blue-500 hover:bg-blue-600"
                  } text-white transition-colors disabled:bg-gray-300`}
                  type="button"
                >
                  {isListening ? <MicOff size={20} /> : <Mic size={20} />}
                  {isListening ? "Stop Listening" : "Start Listening"}
                </button>

                <button
                  onClick={saveText}
                  disabled={!text || isProcessing}
                  className="flex items-center gap-2 px-4 py-2 rounded bg-green-500 hover:bg-green-600 text-white transition-colors disabled:bg-gray-300"
                  type="button"
                >
                  <Save size={20} />
                  Save Text
                </button>
              </div>

              <div className="flex-grow flex flex-col">
                <div className="mb-2 text-sm text-gray-500">
                  {isProcessing
                    ? "Processing..."
                    : isListening
                      ? "Listening..."
                      : "Not listening"}
                </div>
                <div className="flex-grow p-4 bg-white rounded-lg shadow-inner border border-gray-200 focus-within:ring-2 focus-within:ring-blue-500 min-h-[200px]">
                  <div
                    className="h-full"
                    contentEditable
                    suppressContentEditableWarning
                  >
                    {text}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

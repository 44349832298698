import type React from "react";
import type { ProblemFormProps } from "../types";
import ProblemTypeContent from "./ProblemTypeContent";

const ProblemForm: React.FC<ProblemFormProps> = ({ problem, onChange }) => {
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value } = e.target;
    onChange({ ...problem, [name]: value });
  };

  const handleTypeContentUpdate = (field: string, value: string) => {
    onChange({ ...problem, [field]: value });
  };

  return (
    <div className="space-y-4">
      <div>
        <label htmlFor="type" className="block font-bold mb-1">
          Problem Type
        </label>
        <select
          id="type"
          name="type"
          value={problem.type}
          onChange={handleInputChange}
          className="w-full p-2 border rounded"
        >
          <option value="multiple_choice">Multiple Choice</option>
          <option value="short_answer">Short Answer</option>
          <option value="essay">Essay</option>
        </select>
      </div>

      <div>
        <label htmlFor="statement" className="block font-bold mb-1">
          Problem Statement
        </label>
        <textarea
          id="statement"
          name="statement"
          value={problem.statement}
          onChange={handleInputChange}
          rows={5}
          className="w-full p-2 border rounded"
        />
      </div>

      <ProblemTypeContent
        problem={problem}
        mode="edit"
        onUpdate={handleTypeContentUpdate}
      />
    </div>
  );
};

export default ProblemForm;

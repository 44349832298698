import type { AuthError } from "../types";

interface FirebaseErrorDisplayProps {
  error: AuthError;
  onRetry: () => void;
}

export default function FirebaseErrorDisplay({
  error,
  onRetry,
}: FirebaseErrorDisplayProps): JSX.Element {
  const isConfigError =
    error.code.includes("configuration") ||
    error.code === "auth/invalid-api-key";

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full space-y-8 p-8 bg-white rounded-lg shadow-lg">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Authentication Error
          </h2>
          <div className="mt-2 text-center text-sm text-gray-600">
            {isConfigError ? (
              <>
                <p className="font-medium text-red-600">
                  {error.code === "auth/invalid-api-key"
                    ? "Invalid Firebase API Key"
                    : "Firebase Configuration Error"}
                </p>
                <p className="mt-2">
                  {error.code === "auth/invalid-api-key"
                    ? "The Firebase API key is invalid. You will be automatically logged out for security."
                    : "Please check your environment variables and ensure all required Firebase configuration keys are set."}
                </p>
              </>
            ) : (
              <>
                <p className="font-medium text-red-600">
                  Failed to initialize Firebase
                </p>
                <p className="mt-2">
                  There was a problem connecting to our authentication service.
                </p>
              </>
            )}
            <p className="mt-2 text-gray-500">{error.message}</p>
          </div>
        </div>
        <div className="mt-4">
          {!isConfigError && (
            <button
              type="button"
              onClick={onRetry}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Try Again
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

import type { BlogPost, DynamicBlogPost } from "../types";
import { makeAPICall } from "./apiClient";

export interface CreateBlogPostRequest {
  title: string;
  content: string;
  date: string;
  pinned?: boolean;
}

export interface CreateBlogPostResponse {
  id: string;
}

export interface UpdateBlogPostRequest {
  id: string;
  title?: string;
  content?: string;
  pinned?: boolean;
}

export interface UpdateBlogPostResponse {
  success: boolean;
}

export interface FetchBlogPostsResponse {
  posts: DynamicBlogPost[];
}

export async function createBlogPost(
  request: CreateBlogPostRequest,
): Promise<string> {
  const response = await makeAPICall<
    CreateBlogPostRequest,
    CreateBlogPostResponse
  >("createBlogPost", request);
  return response.id;
}

export async function updateBlogPost(
  request: UpdateBlogPostRequest,
): Promise<boolean> {
  const response = await makeAPICall<
    UpdateBlogPostRequest,
    UpdateBlogPostResponse
  >("updateBlogPost", request);
  return response.success;
}

export async function getDynamicBlogPosts(): Promise<DynamicBlogPost[]> {
  const response = await makeAPICall<
    Record<string, never>,
    FetchBlogPostsResponse
  >("fetchBlogPosts", {});
  return response.posts;
}

export function mergeBlogPosts(
  staticPosts: BlogPost[],
  dynamicPosts: DynamicBlogPost[],
): BlogPost[] {
  // Combine static and dynamic posts
  const allPosts = [...staticPosts, ...dynamicPosts];

  // Sort by date, newest first
  return allPosts.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateB.getTime() - dateA.getTime();
  });
}

export function filterPinnedPosts(posts: BlogPost[]): BlogPost[] {
  return posts.filter((post) => post.pinned);
}

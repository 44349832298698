import { useEffect } from "react";
import type { ChallengeRun } from "../types";

interface ExamState {
  currentProblemIndex: number;
  problemResponses: Record<string, { answer: string; timestamp: number }[]>;
  answers: Record<string, string>;
  selectedAnswers: Record<string, string | null>;
  problemTimers: Record<
    string,
    { firstTimer: number; secondTimer: number; firstTimerLocked: boolean }
  >;
  visitedProblems: boolean[];
  isComplete: boolean;
  totalTimePaused: number;
  pauseAfterSubmission: boolean;
  autoSubmit: boolean;
  isPaused: boolean;
  showAllProblems: boolean;
}

interface ExamStateSetters {
  setCurrentProblemIndex: (index: number) => void;
  setProblemResponses: React.Dispatch<
    React.SetStateAction<
      Record<string, { answer: string; timestamp: number }[]>
    >
  >;
  setAnswers: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  setSelectedAnswers: React.Dispatch<
    React.SetStateAction<Record<string, string | null>>
  >;
  setProblemTimers: React.Dispatch<
    React.SetStateAction<
      Record<
        string,
        { firstTimer: number; secondTimer: number; firstTimerLocked: boolean }
      >
    >
  >;
  setVisitedProblems: React.Dispatch<React.SetStateAction<boolean[]>>;
  setIsComplete: React.Dispatch<React.SetStateAction<boolean>>;
  setTotalTimePaused: React.Dispatch<React.SetStateAction<number>>;
  setPauseAfterSubmission: React.Dispatch<React.SetStateAction<boolean>>;
  setAutoSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPaused: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAllProblems: React.Dispatch<React.SetStateAction<boolean>>;
  setTimersInitialized: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useExamStatePersistence = (
  challengeRun: ChallengeRun,
  state: ExamState,
  setters: ExamStateSetters,
  seedInitialAction: () => void,
  loadActionsFromAPI: () => void,
) => {
  // Save state whenever relevant state variables change, debounced
  useEffect(() => {
    const saveCurrentState = () => {
      localStorage.setItem(
        `examState_${challengeRun.id}`,
        JSON.stringify(state),
      );
    };

    const debouncedSaveCurrentState = setTimeout(saveCurrentState, 1000);

    return () => {
      clearTimeout(debouncedSaveCurrentState);
    };
  }, [challengeRun.id, state]);

  // Load state on mount, but only if we don't have a current state
  useEffect(() => {
    const loadSavedState = () => {
      // If we already have a current state (e.g., from navigation),
      // don't overwrite it with localStorage state
      if (
        Object.keys(state.problemResponses).length > 0 ||
        Object.keys(state.answers).length > 0 ||
        Object.keys(state.selectedAnswers).length > 0 ||
        Object.keys(state.problemTimers).length > 0 ||
        state.visitedProblems.length > 0
      ) {
        return;
      }

      const savedState = localStorage.getItem(`examState_${challengeRun.id}`);
      if (savedState) {
        const state = JSON.parse(savedState);
        setters.setCurrentProblemIndex(state.currentProblemIndex);
        setters.setProblemResponses(state.problemResponses);
        setters.setAnswers(state.answers);
        setters.setSelectedAnswers(state.selectedAnswers);
        setters.setProblemTimers(state.problemTimers);
        setters.setVisitedProblems(state.visitedProblems);
        setters.setIsComplete(state.isComplete);
        setters.setTotalTimePaused(state.totalTimePaused);
        setters.setPauseAfterSubmission(state.pauseAfterSubmission);
        setters.setAutoSubmit(state.autoSubmit);
        setters.setIsPaused(state.isPaused);
        setters.setShowAllProblems(state.showAllProblems);
        setters.setTimersInitialized(true); // Ensure timers are marked as initialized
      } else {
        seedInitialAction();
        loadActionsFromAPI();
      }
    };

    loadSavedState();
  }, [challengeRun.id, setters, seedInitialAction, loadActionsFromAPI, state]);
};

import { Star } from "lucide-react";
import { useState } from "react";
import type { SurveyData } from "../api/surveyService";
import { submitSurvey } from "../api/surveyService";
const AMCSurvey = () => {
  const [formData, setFormData] = useState<SurveyData>({
    amcScore: "",
    satisfaction: 0,
    currentMathClasses: [],
    desiredMathClasses: [],
    schoolName: "",
    notes: "",
  });

  const [errors, setErrors] = useState({
    amcScore: "",
    satisfaction: "",
    currentMathClasses: "",
    desiredMathClasses: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [_submitError, setSubmitError] = useState("");

  const mathClasses = [
    "Pre-Algebra",
    "Algebra I",
    "Geometry",
    "Algebra II",
    "Pre-Calculus",
    "AP Calculus AB",
    "AP Calculus BC",
    "Multivariable Calculus",
    "Other",
  ];

  const validateForm = () => {
    const newErrors = {
      amcScore: "",
      satisfaction: "",
      currentMathClasses: "",
      desiredMathClasses: "",
    };

    let isValid = true;

    if (!formData.amcScore) {
      newErrors.amcScore = "Please enter your AMC-10A score";
      isValid = false;
    } else {
      const score = Number(formData.amcScore);
      if (Number.isNaN(score) || score < 0 || score > 150) {
        newErrors.amcScore = "Please enter a valid score between 0 and 150";
        isValid = false;
      }
    }

    if (formData.satisfaction === 0) {
      newErrors.satisfaction = "Please rate your satisfaction";
      isValid = false;
    }

    if (formData.currentMathClasses.length === 0) {
      newErrors.currentMathClasses =
        "Please select at least one current math class";
      isValid = false;
    }

    if (formData.desiredMathClasses.length === 0) {
      newErrors.desiredMathClasses =
        "Please select at least one desired math class";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Temporary block for survey pause
    if (!isSubmitting) return;

    if (!validateForm()) return;

    setIsSubmitting(true);
    setSubmitError("");

    try {
      const response = await submitSurvey(formData);
      if (response.success) {
        setIsSubmitted(true);
      } else {
        setSubmitError("Failed to submit survey. Please try again.");
      }
    } catch (error) {
      setSubmitError("Something went wrong. Please try again.");
      console.error("Survey submission error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleMathClassToggle = (
    className: string,
    type: "current" | "desired",
  ) => {
    setFormData((prev) => {
      const field =
        type === "current" ? "currentMathClasses" : "desiredMathClasses";
      const currentSelection = prev[field];

      if (currentSelection.includes(className)) {
        return {
          ...prev,
          [field]: currentSelection.filter((c) => c !== className),
        };
      }
      return {
        ...prev,
        [field]: [...currentSelection, className],
      };
    });
  };

  const renderMathClassSelector = (type: "current" | "desired") => {
    const field =
      type === "current" ? "currentMathClasses" : "desiredMathClasses";
    const error = errors[field];
    const selectedClasses = formData[field];

    return (
      <div className="space-y-2">
        {mathClasses.map((className) => (
          <label key={className} className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={selectedClasses.includes(className)}
              onChange={() => handleMathClassToggle(className, type)}
              className="rounded border-gray-300 text-blue-500 focus:ring-blue-500"
            />
            <span>{className}</span>
          </label>
        ))}
        {error && <p className="text-red-500 text-sm">{error}</p>}
      </div>
    );
  };

  if (isSubmitted) {
    return (
      <div className="max-w-2xl mx-auto mt-8 p-6 bg-green-50 rounded-lg shadow">
        <h2 className="text-2xl font-bold text-green-800 mb-4">
          Thank you for your response!
        </h2>
        <p className="text-green-700">
          Your survey has been submitted successfully.
        </p>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto mt-8 p-6 bg-white rounded-lg shadow">
      <h1 className="text-2xl font-bold mb-4">AMC-10A Survey</h1>

      {/* Pause Notice */}
      <div className="mb-6 p-4 bg-yellow-50 border border-yellow-200 rounded-lg text-yellow-800">
        We are pausing the survey until everyone has finished AMC-10B and knows
        their scores.
      </div>

      <div className="mb-6 p-4 bg-blue-50 rounded-lg">
        <p className="text-blue-800">
          This survey is to test a hypothesis that your math class in school
          matters more than you think it does.
        </p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* AMC Score */}
        <div>
          <label htmlFor="amcScore" className="block mb-2 font-medium">
            What was your AMC-10A score? (0-150)
            <span className="text-red-500">*</span>
          </label>
          <input
            id="amcScore"
            type="number"
            step="0.5"
            min="0"
            max="150"
            value={formData.amcScore}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, amcScore: e.target.value }))
            }
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
          <p className="mt-1 text-sm text-gray-500">
            Decimal scores are allowed (e.g., 115.5)
          </p>
          {errors.amcScore && (
            <p className="mt-1 text-red-500 text-sm">{errors.amcScore}</p>
          )}
        </div>

        {/* Satisfaction Rating */}
        <div>
          <label htmlFor="satisfaction" className="block mb-2 font-medium">
            How satisfied are you with your score?
            <span className="text-red-500">*</span>
          </label>
          <div id="satisfaction" className="flex gap-2">
            {[1, 2, 3, 4, 5].map((rating) => (
              <button
                key={rating}
                type="button"
                onClick={() =>
                  setFormData((prev) => ({ ...prev, satisfaction: rating }))
                }
                className="focus:outline-none"
              >
                <Star
                  size={24}
                  className={`${
                    rating <= formData.satisfaction
                      ? "fill-yellow-400 text-yellow-400"
                      : "text-gray-300"
                  }`}
                />
              </button>
            ))}
          </div>
          {errors.satisfaction && (
            <p className="mt-1 text-red-500 text-sm">{errors.satisfaction}</p>
          )}
        </div>

        {/* Current Math Classes */}
        <div>
          <label
            htmlFor="currentMathClasses"
            className="block mb-2 font-medium"
          >
            What math class does your school have you in right now?
            <span className="text-red-500">*</span>
          </label>
          <div id="currentMathClasses">
            {renderMathClassSelector("current")}
          </div>
        </div>

        {/* Desired Math Classes */}
        <div>
          <label
            htmlFor="desiredMathClasses"
            className="block mb-2 font-medium"
          >
            What math class do you think you should be in?
            <span className="text-red-500">*</span>
          </label>
          <div id="desiredMathClasses">
            {renderMathClassSelector("desired")}
          </div>
        </div>

        {/* School Name (Optional) */}
        <div>
          <label htmlFor="schoolName" className="block mb-2 font-medium">
            What is the name of your school? (Optional)
          </label>
          <input
            id="schoolName"
            type="text"
            value={formData.schoolName}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, schoolName: e.target.value }))
            }
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Enter your school name"
          />
        </div>

        {/* Notes */}
        <div>
          <label htmlFor="notes" className="block mb-2 font-medium">
            Anything else you want to say? (Optional)
          </label>
          <textarea
            id="notes"
            value={formData.notes}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, notes: e.target.value }))
            }
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500 h-32"
            placeholder="Here is your space to vent"
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          disabled={true}
          className="w-full bg-gray-400 text-white py-2 px-4 rounded cursor-not-allowed opacity-50"
        >
          Submit Survey
        </button>

        <p className="text-sm text-gray-500 text-center">
          Please check back after you have received your AMC-10B scores.
        </p>
      </form>
    </div>
  );
};

export default AMCSurvey;

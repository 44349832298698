import type React from "react";
import type {
  AIMEProblemDetails,
  AMCProblemDetails,
  OlympiadProblemDetails,
  ProblemDetails,
} from "../types";
import LatexRenderer from "./LatexRenderer";

interface ProblemTypeContentProps {
  problem: ProblemDetails;
  mode: "view" | "edit";
  onUpdate?: (field: string, value: string) => void;
}

const ProblemTypeContent: React.FC<ProblemTypeContentProps> = ({
  problem,
  mode,
  onUpdate,
}) => {
  if (problem.type === "multiple_choice") {
    const mcProblem = problem as AMCProblemDetails;

    if (mode === "view") {
      return (
        <div className="mt-2">
          <h4 className="font-semibold">Options:</h4>
          {["A", "B", "C", "D", "E"].map(
            (key) =>
              mcProblem.options[key] && (
                <div
                  key={key}
                  className={`p-2 border rounded mt-1 ${
                    key === mcProblem.correctAnswer
                      ? "bg-green-100 border-green-500"
                      : ""
                  }`}
                >
                  <span className="font-bold mr-2">{key}:</span>
                  <LatexRenderer latex={`$${mcProblem.options[key]}$`} />
                </div>
              ),
          )}
          {!mcProblem.correctAnswer && (
            <p className="text-red-500 mt-2">No correct answer set</p>
          )}
        </div>
      );
    }

    return (
      <div>
        <div className="mb-4">
          <label htmlFor="options" className="block font-bold mb-1">
            Options (A-E)
          </label>
          <textarea
            id="options"
            name="options"
            defaultValue={Object.entries(mcProblem.options)
              .map(([key, value]) => `${key}: ${value}`)
              .join("\n")}
            onChange={(e) => onUpdate?.("options", e.target.value)}
            className="w-full p-2 border rounded font-mono"
            rows={5}
          />
        </div>
        <div>
          <label htmlFor="correctAnswer" className="block font-bold mb-1">
            Correct Answer
          </label>
          <select
            id="correctAnswer"
            name="correctAnswer"
            value={mcProblem.correctAnswer}
            onChange={(e) => onUpdate?.("correctAnswer", e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="">Select correct answer</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
            <option value="E">E</option>
          </select>
        </div>
      </div>
    );
  }

  if (problem.type === "short_answer") {
    const saProblem = problem as AIMEProblemDetails;

    if (mode === "view") {
      return (
        <div className="mt-2">
          <h4 className="font-semibold">Correct Answer:</h4>
          <p>{saProblem.correctAnswer || "No correct answer set"}</p>
        </div>
      );
    }

    return (
      <div>
        <label htmlFor="correctAnswer" className="block font-bold mb-1">
          Correct Answer
        </label>
        <input
          type="text"
          id="correctAnswer"
          name="correctAnswer"
          value={saProblem.correctAnswer}
          onChange={(e) => onUpdate?.("correctAnswer", e.target.value)}
          className="w-full p-2 border rounded"
        />
      </div>
    );
  }

  if (problem.type === "essay") {
    const essayProblem = problem as OlympiadProblemDetails;

    if (mode === "view") {
      return (
        <div className="mt-2">
          <h4 className="font-semibold">Solution Guidelines:</h4>
          <p>
            {essayProblem.solutionGuidelines || "No solution guidelines set"}
          </p>
        </div>
      );
    }

    return (
      <div>
        <label htmlFor="solutionGuidelines" className="block font-bold mb-1">
          Solution Guidelines
        </label>
        <textarea
          id="solutionGuidelines"
          name="solutionGuidelines"
          value={essayProblem.solutionGuidelines || ""}
          onChange={(e) => onUpdate?.("solutionGuidelines", e.target.value)}
          className="w-full p-2 border rounded"
          rows={3}
        />
      </div>
    );
  }

  return null;
};

export default ProblemTypeContent;

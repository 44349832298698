import type { UpdateUserProfileRequest, UserProfile } from "../types";
import { makeAPICall } from "./apiClient";

export interface ListUsersResponse {
  users: UserProfile[];
}

/**
 * List all users (admin only)
 * @returns A promise that resolves to the list of users
 */
export async function listUsers(): Promise<ListUsersResponse> {
  return makeAPICall<void, ListUsersResponse>("listUsers", undefined);
}

/**
 * Update user profile
 * @param data The profile data to update
 * @returns A promise that resolves when the profile is updated
 */
export async function updateUserProfile(
  data: UpdateUserProfileRequest,
): Promise<void> {
  await makeAPICall<UpdateUserProfileRequest, void>("updateUserProfile", data);
}

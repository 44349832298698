export class Lexer {
  public tokenize(input: string): string[] {
    // Strip ASY tags first
    const code = input.replace(/\[asy\]|\[\/asy\]/g, "");

    // Match:
    // - defaultpen, draw and other commands
    // - Numbers (with optional decimal)
    // - Parentheses and brackets
    // - Line operators (--)
    // - Function arguments like linewidth
    // - Commas and semicolons
    const tokenRegex =
      /defaultpen|draw|linewidth|\d+\.?\d*|--|[()[\],;]|[a-zA-Z_][a-zA-Z0-9_]*|[^\s]+/g;

    const tokens = code.match(tokenRegex) || [];
    return tokens.map((t) => t.trim()).filter((t) => t.length > 0);
  }
}

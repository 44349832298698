import type React from "react";
import { useState } from "react";
import { useExamNavigation } from "../hooks/useExamNavigation";
import type {
  ExamContentProps,
  ProblemDetails,
  ProblemReference,
} from "../types";
import ExamContentPresentation from "./ExamContentPresentation";
import ExamProblemRenderer from "./ExamProblemRenderer";

const ExamContent: React.FC<ExamContentProps> = ({
  challengeRun,
  challengeProblems,
  onFinish,
}) => {
  const [showActionLog, setShowActionLog] = useState(false);

  const {
    currentProblemIndex,
    problemResponses,
    selectedAnswers,
    problemTimers,
    totalTimePaused,
    visitedProblems,
    pauseAfterSubmission,
    setPauseAfterSubmission,
    autoSubmit,
    setAutoSubmit,
    isPaused,
    actionLog,
    problems: rawProblems,
    navigateToProblem,
    handleUpdateAnswer,
    handleSkip,
    handleContinue,
    showAllProblems,
    toggleShowAllProblems,
    isComplete,
    setIsComplete,
    handlePrevious,
    handleNext,
    canNavigatePrevious,
    canNavigateNext,
  } = useExamNavigation(challengeRun, challengeProblems);

  const renderProblem = (problemRef: ProblemReference) => {
    // Handle empty challengeProblems
    if (!challengeProblems || challengeProblems.length === 0) {
      return (
        <div className="bg-white p-4 rounded shadow">
          <div className="text-red-600">No problems available</div>
        </div>
      );
    }

    const problem = challengeProblems.find(
      (p) => p.problemId === problemRef.problemId,
    );

    if (!problem) {
      return (
        <div className="bg-white p-4 rounded shadow">
          <div className="text-red-600">Problem not found</div>
        </div>
      );
    }

    // Check for type mismatch before rendering
    if (!("type" in problem) || !("statement" in problem)) {
      return (
        <div className="bg-white p-4 rounded shadow">
          <div className="text-red-600">Problem type not supported</div>
        </div>
      );
    }

    const renderedProblem = (
      <ExamProblemRenderer
        problemRef={problemRef}
        examProblem={problem as ProblemDetails}
        fullProblem={
          { ...problem, ...problemRef } as ProblemDetails & ProblemReference
        }
        onAnswer={(answer) => handleUpdateAnswer(problemRef.label, answer)}
        onSkip={handleSkip}
        selectedAnswer={selectedAnswers[problemRef.label] || null}
        responses={problemResponses[problemRef.label] || []}
        autoSubmit={autoSubmit}
      />
    );

    if (!renderedProblem) {
      return (
        <div className="bg-white p-4 rounded shadow">
          <div className="text-red-600">Problem type not supported</div>
        </div>
      );
    }

    return renderedProblem;
  };

  return (
    <ExamContentPresentation
      currentProblemIndex={currentProblemIndex}
      canNavigatePrevious={canNavigatePrevious}
      canNavigateNext={canNavigateNext}
      onPrevious={handlePrevious}
      onNext={handleNext}
      onNavigate={navigateToProblem}
      problems={rawProblems}
      problemResponses={problemResponses}
      selectedAnswers={selectedAnswers}
      problemTimers={problemTimers}
      visitedProblems={visitedProblems}
      totalTimePaused={totalTimePaused}
      onAnswer={handleUpdateAnswer}
      onSkip={handleSkip}
      showAllProblems={showAllProblems}
      toggleShowAllProblems={toggleShowAllProblems}
      autoSubmit={autoSubmit}
      setAutoSubmit={setAutoSubmit}
      pauseAfterSubmission={pauseAfterSubmission}
      setPauseAfterSubmission={setPauseAfterSubmission}
      isPaused={isPaused}
      onContinue={handleContinue}
      isComplete={isComplete}
      setIsComplete={setIsComplete}
      onFinish={onFinish}
      challengeType={challengeRun.challenge.type}
      startedAt={challengeRun.startedAt}
      showActionLog={showActionLog}
      toggleActionLog={() => setShowActionLog(!showActionLog)}
      actionLog={actionLog}
      renderProblem={renderProblem}
    />
  );
};

export { ExamContent };

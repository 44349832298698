import type { NotificationType } from "../types";
import { makeAPICall } from "./apiClient";

export interface GetNotificationsResponse {
  notifications: NotificationType[];
}

/**
 * Get user notifications
 * @returns A promise that resolves to the user's notifications
 */
export async function getUserNotifications(): Promise<GetNotificationsResponse> {
  return makeAPICall<void, GetNotificationsResponse>(
    "userNotifications",
    undefined,
  );
}

/**
 * Mark a notification as read
 * @param notificationId The ID of the notification to mark as read
 */
export async function markNotificationRead(
  notificationId: string,
): Promise<void> {
  return makeAPICall<{ notificationId: string }, void>("markNotificationRead", {
    notificationId,
  });
}

import type { UserProfile } from "../types";
import { makeAPICall } from "./apiClient";

interface UserProfileResponse {
  user: UserProfile;
}

interface LoginTokenResponse {
  loginToken?: {
    token: string;
  };
}

/**
 * Get user profile
 * @returns A promise that resolves to the user's profile
 */
export async function getUserProfile(): Promise<UserProfile> {
  const response = await makeAPICall<void, UserProfileResponse>(
    "fetchUserProfile",
    undefined,
  );
  return response.user;
}

/**
 * Login user
 */
export async function login(): Promise<void> {
  return makeAPICall<void, void>("login", undefined);
}

/**
 * Logout user
 */
export async function logout(): Promise<void> {
  return makeAPICall<void, void>("logout", undefined);
}

/**
 * Generate a login token for the user
 * @returns A promise that resolves to the login token or null
 */
export async function generateLoginToken(): Promise<string | null> {
  const response = await makeAPICall<
    { generateToken: true },
    LoginTokenResponse
  >("updateUserProfile", { generateToken: true });
  return response.loginToken?.token || null;
}

import { useState } from "react";
import type React from "react";
import { createBlogPost, updateBlogPost } from "../api/blogService";
import type { BlogPost } from "../types";

interface BlogPostEditorProps {
  post?: BlogPost;
  onPostUpdated?: () => void;
  onPostCreated?: () => void;
  onCancel?: () => void;
}

const BlogPostEditor: React.FC<BlogPostEditorProps> = ({
  post,
  onPostUpdated,
  onPostCreated,
  onCancel,
}) => {
  const [title, setTitle] = useState(post?.title ?? "");
  const [content, setContent] = useState(post?.content ?? "");
  const [pinned, setPinned] = useState(post?.pinned ?? false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSaving(true);
    setError(null);

    try {
      if (post) {
        // Update existing post
        await updateBlogPost({
          id: post.id,
          title,
          content,
          pinned,
        });
        onPostUpdated?.();
      } else {
        // Create new post
        await createBlogPost({
          title,
          content,
          date: new Date().toISOString().split("T")[0], // YYYY-MM-DD format
          pinned,
        });
        onPostCreated?.();
        // Clear form after successful creation
        setTitle("");
        setContent("");
        setPinned(false);
      }
    } catch (err) {
      console.error("Error saving blog post:", err);
      setError(
        `Failed to ${post ? "update" : "create"} blog post. Please try again.`,
      );
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="bg-white rounded-lg">
      <h2 className="text-2xl font-bold mb-4">
        {post ? "Edit Blog Post" : "Create New Blog Post"}
      </h2>
      <form onSubmit={handleSubmit} data-testid="blog-post-form">
        <div className="mb-4">
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Title
          </label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="content"
            className="block text-sm font-medium text-gray-700"
          >
            Content
          </label>
          <textarea
            id="content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            rows={10}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            required
          />
          <p className="mt-1 text-sm text-gray-500">
            Supports Markdown formatting
          </p>
        </div>

        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={pinned}
              onChange={(e) => setPinned(e.target.checked)}
              className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            <span className="ml-2 text-sm text-gray-600">Pin this post</span>
          </label>
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
            {error}
          </div>
        )}

        <div className="flex justify-end gap-4">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            disabled={saving}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={saving}
            className={`px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
              saving ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {saving ? "Saving..." : post ? "Update Post" : "Create Post"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default BlogPostEditor;

import { useCallback, useEffect, useState } from "react";
import {
  loadActions as loadActionsAPI,
  recordAction as recordActionAPI,
} from "../api/actionService";
import type { Action, ActionType } from "../types";

const useActionRecorder = (challengeRunId: string) => {
  const [actionLog, setActionLog] = useState<Action[]>([]);

  const createAction = useCallback(
    (
      type: ActionType,
      problemLabel?: string,
      data?: Record<string, unknown>,
    ): Action => {
      return {
        type,
        timestamp: new Date().toISOString(),
        problemLabel,
        data,
      };
    },
    [],
  );

  const recordAction = useCallback(
    async (action: Action) => {
      try {
        await recordActionAPI({
          challengeRunId,
          action,
        });

        setActionLog((prevLog) => {
          const newLog = [...prevLog, action];
          // Only store the current challenge's actions, overwriting any previous challenge
          try {
            localStorage.setItem(
              "currentChallenge",
              JSON.stringify({
                challengeRunId,
                actions: newLog.slice(-10), // Keep only the last 10 actions
              }),
            );
          } catch (storageError) {
            console.warn(
              "Failed to save actions to localStorage:",
              storageError,
            );
          }
          return newLog;
        });
      } catch (error) {
        console.error("Error recording action:", error);
      }
    },
    [challengeRunId],
  );

  const loadActionsFromAPI = useCallback(async () => {
    try {
      const result = await loadActionsAPI({ challengeRunId });

      if (result?.actions && result.actions.length > 0) {
        setActionLog((prevActions) => {
          const combinedActions = [...prevActions, ...result.actions];
          try {
            localStorage.setItem(
              "currentChallenge",
              JSON.stringify({
                challengeRunId,
                actions: combinedActions.slice(-10), // Keep only the last 10 actions
              }),
            );
          } catch (storageError) {
            console.warn(
              "Failed to save actions to localStorage:",
              storageError,
            );
          }
          return combinedActions;
        });
      }
    } catch (error) {
      console.error("Error loading actions:", error);
    }
  }, [challengeRunId]);

  const loadActionsFromLocalStorage = useCallback(() => {
    const savedData = localStorage.getItem("currentChallenge");
    if (savedData) {
      const data = JSON.parse(savedData) as {
        challengeRunId: string;
        actions: Action[];
      };
      // Only load actions if they belong to the current challenge
      if (data.challengeRunId === challengeRunId && data.actions.length > 0) {
        setActionLog(data.actions);
      } else {
        // Clear storage if it's for a different challenge
        localStorage.removeItem("currentChallenge");
      }
    }
  }, [challengeRunId]);

  const seedInitialAction = useCallback(() => {
    const initialAction = createAction("loadSavedState");
    recordAction(initialAction);
  }, [createAction, recordAction]);

  const recordOpenProblem = useCallback(
    (problemLabel: string) => {
      const action = createAction("openProblem", problemLabel);
      recordAction(action);
    },
    [createAction, recordAction],
  );

  const recordNavigateAway = useCallback(
    (problemLabel: string) => {
      const action = createAction("navigateAway", problemLabel);
      recordAction(action);
    },
    [createAction, recordAction],
  );

  const recordSubmitAnswer = useCallback(
    (problemLabel: string, answer: string, timeSpent: number) => {
      const action = createAction("submitAnswer", problemLabel, {
        answer,
        timeSpent,
      });
      recordAction(action);
    },
    [createAction, recordAction],
  );

  const recordSkipProblem = useCallback(
    (problemLabel: string) => {
      const action = createAction("skipProblem", problemLabel);
      recordAction(action);
    },
    [createAction, recordAction],
  );

  const recordViewAllProblems = useCallback(() => {
    const action = createAction("viewAllProblems");
    recordAction(action);
  }, [createAction, recordAction]);

  const recordViewSingleProblem = useCallback(
    (problemLabel: string) => {
      const action = createAction("viewSingleProblem", problemLabel);
      recordAction(action);
    },
    [createAction, recordAction],
  );

  // Load actions from Local Storage on initial render
  useEffect(() => {
    loadActionsFromLocalStorage();
  }, [loadActionsFromLocalStorage]);

  return {
    actionLog,
    recordAction,
    loadActionsFromAPI,
    seedInitialAction,
    recordOpenProblem,
    recordNavigateAway,
    recordSubmitAnswer,
    recordSkipProblem,
    recordViewAllProblems,
    recordViewSingleProblem,
  };
};

export default useActionRecorder;

import { format } from "date-fns";
import type React from "react";
import { useCallback, useEffect, useState } from "react";
import {
  fetchChallengeRuns as apiFetchChallengeRuns,
  gradeChallenge,
} from "../api/challengeService";
import { useAuth } from "../contexts/AuthContext";
import type { ProblemAnalysis } from "../types";
import ChallengeScoringSummary, {
  type EnhancedChallengeRun,
} from "./ChallengeScoringSummary";

const ChallengeScoring: React.FC = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [limit, setLimit] = useState(100);
  const [challengeRuns, setChallengeRuns] = useState<EnhancedChallengeRun[]>(
    [],
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedChallengeRun, setSelectedChallengeRun] =
    useState<EnhancedChallengeRun | null>(null);

  const { currentUser, userProfile, isAdminMode } = useAuth();

  useEffect(() => {
    const today = new Date();
    const twoDaysAgo = new Date(today.getTime() - 2 * 24 * 60 * 60 * 1000);
    const twoDaysAhead = new Date(today.getTime() + 2 * 24 * 60 * 60 * 1000);
    setStartDate(twoDaysAgo.toISOString().split("T")[0]);
    setEndDate(twoDaysAhead.toISOString().split("T")[0]);
  }, []);

  const fetchChallengeRuns = useCallback(async () => {
    if (!currentUser || !isAdminMode) return;

    try {
      setLoading(true);
      const result = await apiFetchChallengeRuns(startDate, endDate, limit);
      setChallengeRuns(result.challengeRuns);
      setError(null);
    } catch (err) {
      setError(
        `Error fetching challenge runs: ${
          err instanceof Error ? err.message : String(err)
        }`,
      );
    } finally {
      setLoading(false);
    }
  }, [currentUser, isAdminMode, startDate, endDate, limit]);

  useEffect(() => {
    if (userProfile?.isAdmin && isAdminMode) {
      fetchChallengeRuns();
    }
  }, [userProfile?.isAdmin, isAdminMode, fetchChallengeRuns]);

  const handleSubmitGrade = async (problemAnalysis: ProblemAnalysis[]) => {
    if (!selectedChallengeRun || !currentUser) return;
    try {
      const result = await gradeChallenge(
        selectedChallengeRun.id,
        problemAnalysis,
      );
      console.log("Challenge graded:", result.status);
      setSelectedChallengeRun(null);
      fetchChallengeRuns();
    } catch (err) {
      setError(
        `Error grading challenge: ${
          err instanceof Error ? err.message : String(err)
        }`,
      );
    }
  };

  if (!userProfile?.isAdmin || !isAdminMode) {
    return null;
  }

  return (
    <div className="container mx-auto py-8">
      <h1 className="text-3xl font-bold mb-6">Challenge Scoring</h1>
      <div className="flex space-x-4 mb-4">
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="border p-2 rounded"
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="border p-2 rounded"
        />
        <input
          type="number"
          value={limit}
          onChange={(e) => setLimit(Number(e.target.value))}
          placeholder="Limit"
          className="border p-2 rounded w-24"
        />
        <button
          onClick={fetchChallengeRuns}
          className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
          type="button"
        >
          Fetch Challenge Runs
        </button>
      </div>

      {error && <div className="text-red-500 mb-4">{error}</div>}

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Challenge Run ID
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  User
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Started At
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Challenge Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Exam Type
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {challengeRuns.map((challengeRun) => (
                <tr key={challengeRun.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {challengeRun.id}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {challengeRun.userName || challengeRun.userId}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {format(
                      new Date(challengeRun.startedAt),
                      "yyyy-MM-dd HH:mm:ss",
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {challengeRun.challenge.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {challengeRun.challenge.examType}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {!challengeRun.completedAt && (
                      <button
                        onClick={() => setSelectedChallengeRun(challengeRun)}
                        className="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600"
                        type="button"
                      >
                        Score
                      </button>
                    )}
                    {challengeRun.completedAt && (
                      <span className="text-gray-500">Completed</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {selectedChallengeRun && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-y-auto p-4">
          <ChallengeScoringSummary
            challengeRun={selectedChallengeRun}
            onClose={() => setSelectedChallengeRun(null)}
            onSubmit={handleSubmitGrade}
          />
        </div>
      )}
    </div>
  );
};

export default ChallengeScoring;

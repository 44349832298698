import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Settings } from "lucide-react";
import type React from "react";
import { useEffect, useState } from "react";
import type { ProblemDetails } from "../types";
import LatexRenderer from "./LatexRenderer";
import ProblemEditor from "./ProblemEditor";
import ProblemTypeContent from "./ProblemTypeContent";

interface EditableProblemCardProps {
  problem: ProblemDetails & {
    problemId: string;
    label: string;
  };
  handleSaveProblem: (
    problemId: string,
    updatedProblem: ProblemDetails,
  ) => Promise<void>;
  handleLabelProblem: (problemId: string, label: string) => Promise<void>;
  syncStatus: boolean | undefined;
}

const EditableProblemCard: React.FC<EditableProblemCardProps> = ({
  problem,
  handleSaveProblem,
  handleLabelProblem,
  syncStatus,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showUserIndexInput, setShowUserIndexInput] = useState(false);
  const [userIndex, setUserIndex] = useState(() => {
    return localStorage.getItem("firestoreUserIndex") || "0";
  });

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: problem.problemId,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  useEffect(() => {
    localStorage.setItem("firestoreUserIndex", userIndex);
  }, [userIndex]);

  const getPreviewContent = (statement: string): string => {
    const maxLength = 150;
    let preview = statement;

    if (preview.length > maxLength) {
      preview = `${preview.substring(0, maxLength)}...`;
    }

    const lastOpenBracket = preview.lastIndexOf("\\[");
    const lastCloseBracket = preview.lastIndexOf("\\]");
    if (lastOpenBracket > lastCloseBracket) {
      preview = `${preview.substring(0, lastOpenBracket)}...`;
    }

    return preview;
  };

  const getFirestoreDebugLink = () => {
    return `https://console.firebase.google.com/u/${userIndex}/project/olympiads/firestore/databases/grindolympiads/data/~2Fproblems~2F${problem.problemId}`;
  };

  const handleUserIndexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserIndex(e.target.value);
  };

  return (
    <div className="mb-4 bg-white shadow-md">
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className="cursor-move p-4 border-b bg-gray-50"
      >
        <div className="flex justify-between items-center">
          <h3 className="text-xl font-semibold">
            Problem {problem.label} ({problem.type})
          </h3>
          <div className="flex items-center space-x-2">
            {syncStatus === false && (
              <button
                onClick={() =>
                  handleLabelProblem(problem.problemId, problem.label)
                }
                className="bg-yellow-500 text-white px-2 py-1 rounded text-sm"
                type="submit"
              >
                Sync Problem
              </button>
            )}
            <div className="relative">
              <button
                onClick={() => setShowUserIndexInput(!showUserIndexInput)}
                className="p-1 rounded-full hover:bg-gray-200"
                title="Change Firestore user index"
                type="submit"
              >
                <Settings size={20} />
              </button>
              {showUserIndexInput && (
                <div className="absolute right-0 mt-2 p-2 bg-white rounded-md shadow-xl z-10">
                  <input
                    type="text"
                    value={userIndex}
                    onChange={handleUserIndexChange}
                    className="border rounded px-2 py-1 w-20"
                    placeholder="User Index"
                  />
                </div>
              )}
            </div>
            <a
              href={getFirestoreDebugLink()}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 text-white px-2 py-1 rounded text-sm"
            >
              Debug in Firestore
            </a>
          </div>
        </div>
      </div>

      <div className="p-4 border rounded">
        {isEditing ? (
          <ProblemEditor
            problem={problem}
            problemLabel={problem.label}
            onSave={(updatedProblem) => {
              handleSaveProblem(problem.problemId, updatedProblem);
              setIsEditing(false);
            }}
            onCancel={() => setIsEditing(false)}
          />
        ) : (
          <>
            <div className="mb-2">
              <LatexRenderer latex={getPreviewContent(problem.statement)} />
            </div>
            <ProblemTypeContent problem={problem} mode="view" />
            <button
              onClick={() => setIsEditing(true)}
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              type="submit"
            >
              Edit Problem
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default EditableProblemCard;

import { Check, Edit2 } from "lucide-react";
import type React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import type { BlogPost } from "../types";
import BlogPostEditor from "./BlogPostEditor";
import { findPostByPath } from "./StaticDevBlogContent";

interface PostHoverCardProps {
  post: BlogPost;
}

const PostHoverCard: React.FC<PostHoverCardProps> = ({ post }) => {
  const formatDate = (dateString: string) => {
    const date = new Date(`${dateString}T12:00:00-08:00`);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <div className="absolute z-50 w-[32rem] p-6 bg-white rounded-lg shadow-xl border border-gray-200 -mt-2">
      <header className="mb-4">
        <h3 className="text-xl font-bold text-gray-900 mb-1">{post.title}</h3>
        <time className="text-sm text-gray-600">{formatDate(post.date)}</time>
      </header>
      <div className="prose prose-sm max-w-none text-gray-700">
        {post.content}
      </div>
    </div>
  );
};

interface BlogPostViewProps {
  post: BlogPost;
  onEdit?: () => void;
  isEditing?: boolean;
  onCancelEdit?: () => void;
  onPostUpdated?: () => void;
  showEditButton?: boolean;
}

const BlogPostView: React.FC<BlogPostViewProps> = ({
  post,
  onEdit,
  isEditing,
  onCancelEdit,
  onPostUpdated,
  showEditButton,
}) => {
  const paragraphs = post.content.split("\n\n");
  const [hoveredPost, setHoveredPost] = useState<BlogPost | null>(null);

  const formatDate = (dateString: string) => {
    const date = new Date(`${dateString}T12:00:00-08:00`);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  if (isEditing) {
    return (
      <BlogPostEditor
        post={post}
        onPostUpdated={onPostUpdated}
        onCancel={onCancelEdit}
      />
    );
  }

  return (
    <article className="mb-12 bg-white rounded-lg shadow-sm overflow-hidden border border-gray-100">
      <div className="px-8 py-6">
        <header className="mb-6">
          <div className="flex items-center justify-between mb-2">
            <h2 className="text-2xl font-bold text-gray-900 flex items-center gap-2">
              {post.title}
              {post.completedDate && (
                <span
                  className="inline-flex items-center"
                  title={`Completed on ${formatDate(post.completedDate)}`}
                >
                  <Check className="w-6 h-6 text-green-500" />
                </span>
              )}
            </h2>
            {showEditButton && (
              <button
                type="button"
                onClick={onEdit}
                className="p-2 text-gray-500 hover:text-blue-600 transition-colors"
                title="Edit post"
              >
                <Edit2 className="w-5 h-5" />
              </button>
            )}
          </div>
          <div className="flex items-center gap-4">
            <time className="text-sm text-gray-600" dateTime={post.date}>
              {formatDate(post.date)}
            </time>
            {post.author && (
              <span className="text-sm text-gray-600">by {post.author}</span>
            )}
          </div>
        </header>

        <div className="prose max-w-none">
          {paragraphs.map((paragraph, index) => {
            const contentKey = `${post.id}-p-${index}-${paragraph.slice(0, 20)}`;

            // Handle lists
            if (paragraph.startsWith("- ")) {
              const items = paragraph.split("\n");
              return (
                <ul
                  key={contentKey}
                  className="list-disc pl-6 mb-6 text-gray-700"
                >
                  {items.map((item) => {
                    const cleanItem = item.replace("- ", "");
                    const itemKey = `${contentKey}-${cleanItem.slice(0, 20)}`;
                    return (
                      <li key={itemKey} className="mb-2">
                        {cleanItem}
                      </li>
                    );
                  })}
                </ul>
              );
            }

            // Handle links
            if (paragraph.includes("[") && paragraph.includes("]")) {
              const parts = paragraph.split(
                /(\[[^\]]*\]\([^()]*(?:\([^()]*\)[^()]*)*\))/g,
              );
              return (
                <p key={contentKey} className="mb-6 text-gray-700">
                  {parts.map((part, pIndex) => {
                    if (
                      part.match(/\[[^\]]*\]\([^()]*(?:\([^()]*\)[^()]*)*\)/)
                    ) {
                      const [, text, url] =
                        part.match(
                          /\[([^\]]*)\]\(([^()]*(?:\([^()]*\)[^()]*)*)\)/,
                        ) || [];
                      const linkKey = `${contentKey}-link-${pIndex}-${url}`;

                      // Check if this is a blog post link
                      const { post: linkedPost } = findPostByPath(url);

                      if (linkedPost) {
                        return (
                          <span
                            key={linkKey}
                            className="relative"
                            onMouseEnter={() => setHoveredPost(linkedPost)}
                            onMouseLeave={() => setHoveredPost(null)}
                          >
                            <Link
                              to={url}
                              className="text-blue-600 hover:text-blue-800 hover:underline"
                            >
                              {text}
                            </Link>
                            {hoveredPost?.id === linkedPost.id && (
                              <PostHoverCard post={linkedPost} />
                            )}
                          </span>
                        );
                      }

                      return (
                        <Link
                          key={linkKey}
                          to={url}
                          className="text-blue-600 hover:text-blue-800 hover:underline"
                        >
                          {text}
                        </Link>
                      );
                    }
                    return part;
                  })}
                </p>
              );
            }

            // Regular paragraphs
            return (
              <p
                key={contentKey}
                className="mb-6 text-gray-700 whitespace-pre-line"
              >
                {paragraph}
              </p>
            );
          })}
        </div>
      </div>
    </article>
  );
};

export default BlogPostView;

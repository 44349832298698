import type { Exam } from "../types";
import { makeAPICall } from "./apiClient";

export interface FetchExamsResponse {
  tests: Exam[];
}

/**
 * Get a list of all available exams.
 * @returns A promise that resolves to the list of exams
 */
export async function fetchExams(): Promise<FetchExamsResponse> {
  return makeAPICall<void, FetchExamsResponse>("fetchExams", undefined);
}

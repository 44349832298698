import type * as API from "@anthropic-ai/sdk/resources/index";
import { makeAPICall } from "./apiClient";

export interface AnthropicProxyRequest {
  apiKey: string;
  model: string;
  max_tokens: number;
  messages: API.MessageParam[];
  tools: API.Tool[];
}

export interface AnthropicProxyResponse {
  content: { text: string; type: string }[];
}

/**
 * Send a request to the Anthropic proxy
 * @param request The request parameters for the Anthropic API
 * @returns A promise that resolves to the Anthropic API response
 */
export async function anthropicProxy(
  request: AnthropicProxyRequest,
): Promise<AnthropicProxyResponse> {
  return makeAPICall<AnthropicProxyRequest, AnthropicProxyResponse>(
    "anthropicProxy",
    request,
  );
}

import { type FirebaseApp, initializeApp } from "firebase/app";
import { type Auth, getAuth } from "firebase/auth";

interface FirebaseInitResult {
  app: FirebaseApp | null;
  auth: Auth | null;
  error: Error | null;
}

function validateConfig(config: Record<string, string | undefined>): string[] {
  const missingKeys = [];
  for (const [key, value] of Object.entries(config)) {
    if (!value) {
      missingKeys.push(key);
    }
  }
  return missingKeys;
}

export function initializeFirebase(): FirebaseInitResult {
  try {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
    };

    const missingKeys = validateConfig(firebaseConfig);
    if (missingKeys.length > 0) {
      throw new Error(
        `Missing required Firebase configuration keys: ${missingKeys.join(", ")}`,
      );
    }

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    return { app, auth, error: null };
  } catch (error) {
    console.error("Firebase initialization failed:", error);
    return {
      app: null,
      auth: null,
      error:
        error instanceof Error
          ? error
          : new Error("Unknown error during Firebase initialization"),
    };
  }
}

const { auth } = initializeFirebase();
export { auth };

import { getFunctions, httpsCallable } from "firebase/functions";

/**
 * Gets Firebase Functions instance
 * @returns Firebase Functions instance
 */
export function getFunctionInstance() {
  return getFunctions();
}

/**
 * Makes an API call to a Firebase Cloud Function
 * @param name - The name of the function to call
 * @param data - The data to pass to the function
 * @returns A promise that resolves to the response data of type R.
 */
async function makeAPICall<T = void, R = unknown>(
  name: string,
  data?: T,
): Promise<R> {
  const func = httpsCallable<T, R>(getFunctionInstance(), name);
  const result = await func(data as T);

  const resData = result.data;

  if (resData && typeof resData === "object" && "data" in resData) {
    console.warn(
      `Warning: Function "${name}" returned data nested under a 'data' property.`,
    );
    return resData.data as R;
  }
  return resData as R;
}

export { makeAPICall };

import { Plus } from "lucide-react";
import type React from "react";
import { useState } from "react";
import { getDynamicBlogPosts } from "../api/blogService";
import { useAuth } from "../contexts/AuthContext";
import BlogPostEditor from "./BlogPostEditor";

const AdminBlogPage: React.FC = () => {
  const { isAdminMode } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  if (!isAdminMode) {
    return null;
  }

  return (
    <div className="container mx-auto py-8 px-4">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">Admin Blog Management</h1>
        <button
          onClick={() => setIsEditing(true)}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-blue-600 transition-colors"
          type="button"
        >
          <Plus size={20} />
          New Post
        </button>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded mb-6">
          {error}
        </div>
      )}

      {isEditing && (
        <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
          <BlogPostEditor
            onPostCreated={() => {
              setIsEditing(false);
              setError(null);
              // Refresh blog posts after creation
              getDynamicBlogPosts();
            }}
            onCancel={() => setIsEditing(false)}
          />
        </div>
      )}
    </div>
  );
};

export default AdminBlogPage;

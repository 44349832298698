import { ChevronLeft, ChevronRight } from "lucide-react";
import type React from "react";
import type { ProblemReference, TimestampedResponse } from "../types";
import ChallengeStatusPage from "./ChallengeStatusPage";
import ProblemNavigation from "./ProblemNavigation";

interface ExamContentPresentationProps {
  // Navigation props
  currentProblemIndex: number;
  canNavigatePrevious: boolean;
  canNavigateNext: boolean;
  onPrevious: () => void;
  onNext: () => void;
  onNavigate: (index: number) => void;

  // Problem state
  problems: ProblemReference[];
  problemResponses: Record<string, TimestampedResponse[]>;
  selectedAnswers: Record<string, string>;
  problemTimers: Record<
    string,
    { firstTimer: number; secondTimer: number; firstTimerLocked: boolean }
  >;
  visitedProblems: boolean[];
  totalTimePaused: number;

  // Problem actions
  onAnswer: (label: string, answer: string) => void;
  onSkip: () => void;

  // View controls
  showAllProblems: boolean;
  toggleShowAllProblems: () => void;
  autoSubmit: boolean;
  setAutoSubmit: (value: boolean) => void;
  pauseAfterSubmission: boolean;
  setPauseAfterSubmission: (value: boolean) => void;

  // Pause state
  isPaused: boolean;
  onContinue: () => void;

  // Completion state
  isComplete: boolean;
  setIsComplete: (value: boolean) => void;
  onFinish: () => void;

  // Challenge info
  challengeType: string;
  startedAt: string;

  // Action log
  showActionLog: boolean;
  toggleActionLog: () => void;
  actionLog: Array<{
    type: string;
    timestamp: string;
    problemLabel?: string;
    data?: Record<string, unknown>;
  }>;

  // Problem rendering
  renderProblem: (problemRef: ProblemReference) => React.ReactNode;
}

const ExamContentPresentation: React.FC<ExamContentPresentationProps> = ({
  currentProblemIndex,
  canNavigatePrevious,
  canNavigateNext,
  onPrevious,
  onNext,
  onNavigate,
  problems,
  problemResponses,
  problemTimers,
  visitedProblems,
  totalTimePaused,
  showAllProblems,
  toggleShowAllProblems,
  autoSubmit,
  setAutoSubmit,
  pauseAfterSubmission,
  setPauseAfterSubmission,
  isPaused,
  onContinue,
  isComplete,
  setIsComplete,
  onFinish,
  challengeType,
  startedAt,
  showActionLog,
  toggleActionLog,
  actionLog,
  renderProblem,
}) => {
  const formatChallengeStartTime = (time: string) => {
    const startDate = new Date(time);
    // Force UTC interpretation
    const utcHours = startDate.getUTCHours();
    const utcMinutes = startDate.getUTCMinutes();
    const period = utcHours >= 12 ? "PM" : "AM";
    const hours = utcHours % 12 || 12;
    const timeString = `${hours}:${utcMinutes.toString().padStart(2, "0")} ${period}`;

    const month = startDate.getUTCMonth() + 1;
    const day = startDate.getUTCDate();
    const year = startDate.getUTCFullYear();
    const dateString = `${month}/${day}/${year}`;

    return `Started at ${timeString} on ${dateString}`;
  };

  const renderChallengeType = () => {
    const displayType = ["11-15", "16-20", "21-25"].includes(challengeType)
      ? `${challengeType} Challenge`
      : `${challengeType.charAt(0).toUpperCase() + challengeType.slice(1)} Challenge`;

    return (
      <div className="bg-blue-100 text-blue-800 px-4 py-2 rounded">
        <div>{displayType}</div>
        <div className="text-xs mt-1">
          {formatChallengeStartTime(startedAt)}
        </div>
      </div>
    );
  };

  const renderActionLog = () => (
    <>
      <button
        onClick={toggleActionLog}
        className="mt-4 bg-gray-200 text-gray-800 px-4 py-2 rounded"
        type="submit"
      >
        {showActionLog ? "Hide Action Log" : "Show Action Log"}
      </button>
      {showActionLog && (
        <div className="mt-4 p-4 border rounded max-h-60 overflow-y-auto">
          <h3 className="font-bold mb-2">Action Log:</h3>
          {actionLog.map((action) => (
            <div key={`action-${action.timestamp}`} className="mb-2">
              <span className="font-semibold">{action.type}</span> -{" "}
              {action.timestamp} - Problem: {action.problemLabel || "N/A"}
              {action.data && (
                <span> - Data: {JSON.stringify(action.data)}</span>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );

  if (isComplete) {
    return (
      <ChallengeStatusPage
        problemResponses={problemResponses}
        problems={problems}
        onContinue={() => setIsComplete(false)}
        onFinish={onFinish}
        isChallenge={true}
      />
    );
  }

  return (
    <div>
      <div className="mb-4 flex justify-between items-center">
        {challengeType === "full" && (
          <button
            onClick={toggleShowAllProblems}
            className="bg-green-500 text-white px-4 py-2 rounded"
            type="submit"
          >
            {showAllProblems ? "Show One Problem" : "Show All Problems"}
          </button>
        )}
        <div>
          <label className="flex items-center mr-4">
            <input
              type="checkbox"
              checked={pauseAfterSubmission}
              onChange={(e) => setPauseAfterSubmission(e.target.checked)}
              className="mr-2"
            />
            Pause after submission
          </label>
          {!showAllProblems && (
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={autoSubmit}
                onChange={(e) => setAutoSubmit(e.target.checked)}
                className="mr-2"
              />
              Auto-submit and move to next problem
            </label>
          )}
        </div>
        {renderChallengeType()}
      </div>

      <ProblemNavigation
        problems={problems}
        currentIndex={currentProblemIndex}
        onNavigate={onNavigate}
        responses={problemResponses}
        problemTimers={problemTimers}
        totalTimePaused={totalTimePaused}
        visitedProblems={visitedProblems}
      />

      {isPaused ? (
        <div className="text-center py-8">
          <h2 className="text-2xl font-bold mb-4">Paused</h2>
          <p className="mb-4">
            The timers are paused. You are heavily discouraged from working on
            the problems until things resume.
          </p>
          <button
            onClick={onContinue}
            className="bg-blue-500 text-white px-4 py-2 rounded"
            type="submit"
          >
            View Next Problem And Restart Timers
          </button>
        </div>
      ) : showAllProblems && challengeType === "full" ? (
        <ul className="space-y-4">
          {problems.map((problem) => (
            <li key={problem.problemId}>{renderProblem(problem)}</li>
          ))}
        </ul>
      ) : (
        <div>
          {renderProblem(problems[currentProblemIndex])}
          {challengeType === "full" && (
            <div className="flex justify-between mt-4">
              <button
                onClick={onPrevious}
                className="bg-blue-500 text-white px-4 py-2 rounded disabled:bg-gray-300"
                disabled={!canNavigatePrevious}
                type="submit"
              >
                <ChevronLeft className="h-5 w-5 mr-2" />
                Previous
              </button>
              <button
                onClick={onNext}
                className="bg-blue-500 text-white px-4 py-2 rounded disabled:bg-gray-300"
                disabled={!canNavigateNext}
                type="submit"
              >
                Next
                <ChevronRight className="h-5 w-5 ml-2" />
              </button>
            </div>
          )}
        </div>
      )}
      {renderActionLog()}
    </div>
  );
};

export default ExamContentPresentation;

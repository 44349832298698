import {
  GoogleAuthProvider,
  type User,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { auth, initializeFirebase } from "../firebase";
import type {
  AuthContextType,
  AuthError,
  AuthProviderProps,
  AuthState,
  LoginFunction,
  UserProfile,
} from "../types";

import {
  generateLoginToken,
  getUserProfile,
  login as loginAPI,
  logout as logoutAPI,
} from "../api/authService";
export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);

export function useAuth(): AuthContextType {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const [state, setState] = useState<AuthState>({
    currentUser: null,
    userProfile: null,
    viewAsUserProfile: null,
    isAdminMode: sessionStorage.getItem("isAdminMode") === "true",
    initError: null,
    profileError: null,
    profileLoading: false,
  });
  const [loading, setLoading] = useState(true);

  const initialLoadComplete = useRef(false);

  useEffect(() => {
    sessionStorage.setItem("isAdminMode", (!!state.isAdminMode).toString());
  }, [state.isAdminMode]);

  useEffect(() => {
    if (!initialLoadComplete.current && state.userProfile) {
      setState((prev) => ({
        ...prev,
        viewAsUserProfile: state.userProfile,
      }));
      initialLoadComplete.current = true;
    }
  }, [state.userProfile]);

  const fetchUserProfile = useCallback(async () => {
    if (!state.currentUser) {
      return;
    }

    setState((prev) => ({ ...prev, profileLoading: true }));
    try {
      const newProfile = await getUserProfile();
      setState((prev) => ({
        ...prev,
        userProfile: newProfile,
        profileError: null,
        profileLoading: false,
      }));
    } catch (error) {
      console.error("Error fetching user profile:", error);
      setState((prev) => ({
        ...prev,
        profileError: "Failed to fetch user profile",
        userProfile: null,
        profileLoading: false,
      }));
    }
  }, [state.currentUser]);

  const initializeAuth = useCallback(async () => {
    const { auth: newAuth, error } = initializeFirebase();

    if (error) {
      const errorCode = error.message.includes("invalid-api-key")
        ? "auth/invalid-api-key"
        : error.name;
      setState((prev) => ({
        ...prev,
        initError: {
          code: errorCode,
          message: error.message,
        },
        // Automatically clear user state for invalid API key
        ...(errorCode === "auth/invalid-api-key" && {
          currentUser: null,
          userProfile: null,
          viewAsUserProfile: null,
          isAdminMode: false,
        }),
      }));
      setLoading(false);
      return null;
    }

    if (!newAuth) {
      setState((prev) => ({
        ...prev,
        initError: {
          code: "unknown",
          message: "Failed to initialize Firebase authentication",
        },
      }));
      setLoading(false);
      return null;
    }

    return new Promise<(() => void) | null>((resolve) => {
      const unsubscribe = newAuth.onAuthStateChanged((user) => {
        setState((prev) => ({ ...prev, currentUser: user }));
        if (user) {
          fetchUserProfile().catch((error) => {
            console.error("Error fetching user profile:", error);
            setState((prev) => ({
              ...prev,
              profileError: "Failed to fetch user profile",
              userProfile: null,
            }));
          });
        } else {
          setState((prev) => ({
            ...prev,
            userProfile: null,
            viewAsUserProfile: null,
            profileError: null,
          }));
          initialLoadComplete.current = false;
        }
        setLoading(false);
        resolve(unsubscribe);
      });
    });
  }, [fetchUserProfile]);

  useEffect(() => {
    let cleanup: (() => void) | null = null;
    initializeAuth().then((unsubscribe) => {
      cleanup = unsubscribe;
    });
    return () => {
      if (cleanup) {
        cleanup();
      }
    };
  }, [initializeAuth]);

  const refreshUserProfile = useCallback(async () => {
    if (state.currentUser) {
      await fetchUserProfile();
    }
  }, [state.currentUser, fetchUserProfile]);

  const retryInitialization = useCallback(async () => {
    setState((prev) => ({ ...prev, initError: null }));
    await initializeAuth();
  }, [initializeAuth]);

  const login: LoginFunction = async () => {
    if (!auth) {
      setState((prev) => ({
        ...prev,
        initError: {
          code: "auth/not-initialized",
          message: "Firebase authentication is not initialized",
        },
      }));
      return;
    }

    try {
      const result = await signInWithPopup(auth, new GoogleAuthProvider());
      if (result.user) {
        setState((prev) => ({ ...prev, currentUser: result.user }));
        await loginAPI();
        await fetchUserProfile();
      }
    } catch (error) {
      console.error("Failed to log in:", error);
      setState((prev) => ({
        ...prev,
        profileError: "Failed to fetch user profile",
        currentUser: null,
        userProfile: null,
        viewAsUserProfile: null,
      }));
    }
  };

  const logout = async (): Promise<void> => {
    if (!auth) {
      setState((prev) => ({
        ...prev,
        initError: {
          code: "auth/not-initialized",
          message: "Firebase authentication is not initialized",
        },
      }));
      return;
    }

    try {
      await logoutAPI();
      await signOut(auth);
      setState((prev) => ({
        ...prev,
        profileError: null,
        currentUser: null,
        userProfile: null,
        viewAsUserProfile: null,
        isAdminMode: false,
      }));
      initialLoadComplete.current = false;
    } catch (error) {
      console.error("Failed to log out:", error);
      setState((prev) => ({
        ...prev,
        profileError: "Failed to log out",
      }));
    }
  };

  const toggleAdminMode = () => {
    if (state.userProfile?.isAdmin) {
      setState((prev) => ({
        ...prev,
        isAdminMode: !prev.isAdminMode,
      }));
    }
  };

  const generateLoginTokenAndRefresh = async (): Promise<string | null> => {
    if (!state.currentUser) {
      return null;
    }

    try {
      const token = await generateLoginToken();
      await fetchUserProfile();
      return token;
    } catch (error) {
      console.error("Error generating login token:", error);
      return null;
    }
  };

  const value: AuthContextType = {
    ...state,
    login,
    logout,
    toggleAdminMode,
    refreshUserProfile,
    retryInitialization,
    generateLoginToken: generateLoginTokenAndRefresh,
    setViewAsUserProfile: (profile) =>
      setState((prev) => ({
        ...prev,
        viewAsUserProfile: profile,
      })),
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

import { ChevronRight } from "lucide-react";
import type React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { getDynamicBlogPosts, mergeBlogPosts } from "../api/blogService";
import { useAuth } from "../contexts/AuthContext";
import type { DynamicBlogPost } from "../types";
import BlogPostView from "./BlogPostView";
import { staticBlogPosts } from "./StaticDevBlogContent";

const DevBlog: React.FC = () => {
  const [activeTab, setActiveTab] = useState("Updates");
  const [dynamicPosts, setDynamicPosts] = useState<DynamicBlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [editingPostId, setEditingPostId] = useState<string | null>(null);
  const roadmapRef = useRef<HTMLDivElement>(null);
  const { isAdminMode } = useAuth();

  const scrollToRoadmap = useCallback(() => {
    roadmapRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const handlePostUpdated = useCallback(() => {
    setEditingPostId(null);
    // Refresh the posts
    const loadDynamicPosts = async () => {
      try {
        const posts = await getDynamicBlogPosts();
        setDynamicPosts(posts);
        setError(null);
      } catch (err) {
        console.error("Error loading dynamic blog posts:", err);
        setError("Failed to load some blog posts. Please try again later.");
      }
    };
    loadDynamicPosts();
  }, []);

  useEffect(() => {
    const loadDynamicPosts = async () => {
      try {
        setLoading(true);
        const posts = await getDynamicBlogPosts();
        setDynamicPosts(posts);
        setError(null);
      } catch (err) {
        console.error("Error loading dynamic blog posts:", err);
        setError("Failed to load some blog posts. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    loadDynamicPosts();
  }, []);

  useEffect(() => {
    if (activeTab === "Roadmap") {
      scrollToRoadmap();
    }
  }, [activeTab, scrollToRoadmap]);

  const mergedPosts = useMemo(() => {
    const staticPosts = staticBlogPosts[activeTab] || [];
    // Only merge dynamic posts in the Updates section
    if (activeTab === "Updates") {
      const dynamicPostsFiltered = dynamicPosts.filter((post) => !post.pinned);
      return mergeBlogPosts(staticPosts, dynamicPostsFiltered);
    }
    return staticPosts;
  }, [activeTab, dynamicPosts]);

  const pinnedPosts = useMemo(() => {
    // Only show pinned posts in the Updates section
    if (activeTab === "Updates") {
      return dynamicPosts.filter((post) => post.pinned);
    }
    return [];
  }, [activeTab, dynamicPosts]);

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4">
        <div className="mb-8">
          <div className="flex items-center text-sm text-gray-500 mb-4">
            <Link to="/" className="hover:text-blue-600">
              Home
            </Link>
            <ChevronRight size={16} className="mx-2" />
            <span>Developer Blog</span>
          </div>
          <h1 className="text-4xl font-bold text-gray-900 mb-2">
            Developer Updates
          </h1>
          <p className="text-lg text-gray-600">
            Stay up to date with the latest technical improvements and platform
            updates
          </p>
        </div>

        <div className="mb-8">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8">
              {Object.keys(staticBlogPosts).map((tabName) => (
                <button
                  key={`tab-${tabName}`}
                  type="button"
                  onClick={() => setActiveTab(tabName)}
                  className={`py-4 px-1 border-b-2 font-medium text-sm ${
                    activeTab === tabName
                      ? "border-blue-500 text-blue-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  }`}
                >
                  {tabName}
                </button>
              ))}
            </nav>
          </div>
        </div>

        {/* Updates section */}
        <div
          data-testid="updates-content"
          className={`space-y-8 ${activeTab === "Updates" ? "" : "hidden"}`}
        >
          {loading && (
            <div data-testid="loading-state" className="text-center py-8">
              Loading posts...
            </div>
          )}
          {error && (
            <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-8 text-red-700">
              {error}
            </div>
          )}
          {pinnedPosts.map((post) => (
            <BlogPostView
              key={`pinned-${post.id}`}
              post={post}
              showEditButton={isAdminMode}
              onEdit={() => setEditingPostId(post.id)}
              isEditing={editingPostId === post.id}
              onCancelEdit={() => setEditingPostId(null)}
              onPostUpdated={handlePostUpdated}
            />
          ))}
          {mergedPosts.map((post) => (
            <BlogPostView
              key={`post-${post.id}`}
              post={post}
              showEditButton={isAdminMode}
              onEdit={() => setEditingPostId(post.id)}
              isEditing={editingPostId === post.id}
              onCancelEdit={() => setEditingPostId(null)}
              onPostUpdated={handlePostUpdated}
            />
          ))}
        </div>

        {/* Roadmap section */}
        <div
          ref={roadmapRef}
          data-testid="roadmap-content"
          className={`space-y-8 ${activeTab === "Roadmap" ? "" : "hidden"}`}
        >
          {loading && (
            <div
              data-testid="roadmap-loading-state"
              className="text-center py-8"
            >
              Loading posts...
            </div>
          )}
          {error && (
            <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-8 text-red-700">
              {error}
            </div>
          )}
          {staticBlogPosts.Roadmap.map((post) => (
            <BlogPostView
              key={`post-${post.id}`}
              post={post}
              showEditButton={isAdminMode}
              onEdit={() => setEditingPostId(post.id)}
              isEditing={editingPostId === post.id}
              onCancelEdit={() => setEditingPostId(null)}
              onPostUpdated={handlePostUpdated}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DevBlog;

import { DndContext, type DragEndEvent } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import type React from "react";
import type {
  AIMEProblemDetails,
  AMCProblemDetails,
  OlympiadProblemDetails,
  ProblemDetails,
  ProblemListProps,
  ProblemReference,
} from "../types";
import EditableProblemCard from "./EditableProblemCard";

const DraggableProblemList: React.FC<ProblemListProps> = ({
  problems,
  setProblems,
  handleSaveProblem,
  handleLabelProblem,
  syncStatus,
}) => {
  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;

    if (active.id !== over.id) {
      const oldIndex = problems.findIndex(
        (item) => item.problemId === active.id,
      );
      const newIndex = problems.findIndex((item) => item.problemId === over.id);
      setProblems((items) => arrayMove(items, oldIndex, newIndex));
    }
  };

  const transformProblem = (
    problem: Partial<ProblemReference> & Partial<ProblemDetails>,
  ): ProblemDetails & { problemId: string; label: string } => {
    const baseProblem = {
      problemId: problem.problemId,
      label: problem.label,
      statement: problem.statement || "",
      type: problem.type || "multiple_choice",
    };

    switch (problem.type) {
      case "multiple_choice":
        return {
          ...baseProblem,
          options: problem.options || {},
          correctAnswer: problem.correctAnswer || "",
        } as AMCProblemDetails & { problemId: string; label: string };
      case "short_answer":
        return {
          ...baseProblem,
          correctAnswer: "",
        } as AIMEProblemDetails & { problemId: string; label: string };
      case "essay":
        return {
          ...baseProblem,
          solutionGuidelines: "",
        } as OlympiadProblemDetails & { problemId: string; label: string };
      default:
        return baseProblem as ProblemDetails & {
          problemId: string;
          label: string;
        };
    }
  };

  return (
    <DndContext onDragEnd={onDragEnd}>
      <SortableContext
        items={problems.map((p) => p.problemId)}
        strategy={verticalListSortingStrategy}
      >
        <div>
          {problems.map((problem, _index) => (
            <EditableProblemCard
              key={problem.problemId}
              problem={transformProblem(problem)}
              handleSaveProblem={handleSaveProblem}
              handleLabelProblem={handleLabelProblem}
              syncStatus={syncStatus[problem.problemId]}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default DraggableProblemList;

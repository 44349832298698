import type { EnhancedAction } from "../types";
import { makeAPICall } from "./apiClient";

export interface FetchActionsResponse {
  actions: EnhancedAction[];
}

export interface PopulateActionsResponse {
  message: string;
  stats: {
    actionsAlreadyPresent: number;
    actionsAdded: number;
    challengeRunsProcessed: number;
  };
}

/**
 * Fetch admin actions for a specific date
 * @param date The date to fetch actions for
 * @returns A promise that resolves to the actions response
 */
export async function fetchAdminActions(
  date: string,
): Promise<FetchActionsResponse> {
  return makeAPICall<{ date: string }, FetchActionsResponse>(
    "adminFetchActions",
    {
      date,
    },
  );
}

/**
 * Populate all actions
 * @returns A promise that resolves to the populate response
 */
export async function populateActions(): Promise<PopulateActionsResponse> {
  return makeAPICall<void, PopulateActionsResponse>(
    "populateActions",
    undefined,
  );
}

/**
 * Delete a challenge run
 * @param challengeRunId The ID of the challenge run to delete
 * @returns A promise that resolves when deletion is complete
 */
export async function deleteChallengeRun(
  challengeRunId: string,
): Promise<void> {
  return makeAPICall<{ challengeRunId: string }, void>("deleteChallengeRun", {
    challengeRunId,
  });
}

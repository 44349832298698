import { HardHat, Star } from "lucide-react";
import type React from "react";
import { useEffect, useRef, useState } from "react";
import type { GradedChallenge, ProgressTokenProps } from "../types";

const ProgressToken: React.FC<ProgressTokenProps> = ({
  challengeId,
  gradedChallenge,
  onClick,
  isDisabled,
  tooltip,
  ...props
}) => {
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (showTooltip && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setTooltipPosition({
        x: rect.left + rect.width / 2,
        y: rect.top - 8,
      });
    }
  }, [showTooltip]);

  const getStatusColor = () => {
    if (!challengeId) return "#FFF3CD";
    if (!gradedChallenge) return "transparent";

    switch (gradedChallenge.status) {
      case "worked_on":
        return "#CD7F32";
      case "answer_checked":
        return "linear-gradient(135deg, #C0C0C0 0%, #FFFFFF 50%, #C0C0C0 100%)";
      case "reviewed":
        return "linear-gradient(135deg, #FFD700 0%, #FFF5CC 50%, #FFD700 100%)";
      default:
        return "transparent";
    }
  };

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        type="button"
        className={`w-6 h-6 flex justify-center items-center rounded-full
          focus:outline-none focus:ring-1 focus:ring-blue-500 transition-colors
          ${!gradedChallenge ? "border-2 border-dashed border-gray-300" : ""}
          ${
            challengeId === null
              ? "cursor-help"
              : isDisabled
                ? "cursor-not-allowed"
                : "cursor-pointer"
          }
        `}
        style={{
          background: getStatusColor(),
          boxShadow: gradedChallenge ? "0 1px 2px rgba(0,0,0,0.2)" : "none",
        }}
        onClick={!isDisabled ? onClick : undefined}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        disabled={challengeId === null || isDisabled}
        {...props}
      >
        {challengeId === null ? (
          <HardHat size={12} className="text-amber-500" />
        ) : (
          gradedChallenge && (
            <Star
              size={12}
              className={`${
                gradedChallenge.status === "reviewed"
                  ? "text-yellow-500"
                  : gradedChallenge.status === "answer_checked"
                    ? "text-gray-500"
                    : gradedChallenge.status === "worked_on"
                      ? "text-amber-900"
                      : ""
              }`}
            />
          )
        )}
      </button>

      {showTooltip && (
        <div
          className="fixed z-50 w-80 px-4 py-2 text-sm bg-gray-900 text-white rounded-lg pointer-events-none"
          style={{
            left: tooltipPosition.x,
            top: tooltipPosition.y,
            transform: "translate(-50%, -100%)",
          }}
          data-testid={`${props["data-testid"]}-tooltip`}
        >
          {tooltip}
          <div
            className="absolute w-2 h-2 bg-gray-900 transform rotate-45"
            style={{
              left: "50%",
              bottom: "-4px",
              marginLeft: "-4px",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProgressToken;
